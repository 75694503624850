import { Component, OnInit, AfterContentInit, AfterContentChecked, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { Router } from '@angular/router';
import { take } from "rxjs/operators";
import * as ia from '../../aiHandlers/carHandler.js';
import { Subscription } from 'rxjs';
import { ISilhouette } from 'projects/remote-library/src/interfaces/selfadjust/silhouette.interfaces.js';
import { ImageValidation } from 'projects/remote-library/src/interfaces/selfadjust/ImageValidation.interfaces.js';
import { ImageType } from '../../../../remote-library/src/lib/types/image-type.type.js';
import { ICheckImageResult } from '../../../../remote-library/src/lib/interfaces/check-image-result.interface.js';
import { IWarnings } from '../../../../remote-library/src/lib/interfaces/warnings.interface.js';

declare var window: Window & { screen: any };

type Warning = {
  name: string,
  result: boolean,
  count?: number
}

@Component({
  selector: 'app-side-pictures',
  templateUrl: './side-pictures.component.html',
  styleUrls: [
    './side-pictures.component.scss'
  ]
})
export class SidePicturesComponent implements OnInit, AfterContentInit, AfterContentChecked, OnDestroy {

  public pictureDatas: any = [
    {
      id: 'front', name: 'Front', name_es: 'Parte delantera', type: 'front', image: null, object2detect: 'license',
      bgImage: '../../assets/images/logo/camera.svg', isBGShow: true, newImg: 'newFront', uploadOK: true, enableAI: false
    },
    {
      id: 'rear', name: 'Rear', name_es: 'Parte trasera', type: 'rear', image: null, object2detect: 'license',
      bgImage: '../../assets/images/logo/camera.svg', isBGShow: true, newImg: 'newRear', uploadOK: true, enableAI: false
    },
    {
      id: 'rear-left', name: 'Left', name_es: 'Lat. izquierdo', type: 'rearLeft', image: null, object2detect: 'wheel',
      bgImage: '../../assets/images/logo/camera.svg', isBGShow: true, newImg: 'newLeft', uploadOK: true, enableAI: false
    },
    {
      id: 'front-left', name: 'Left', name_es: 'Lat. izquierdo', type: 'frontLeft', image: null, object2detect: 'wheel',
      bgImage: '../../assets/images/logo/camera.svg', isBGShow: true, newImg: 'newFrontLeft', uploadOK: true, enableAI: false
    },
    {
      id: 'rear-right', name: 'Right', name_es: 'Lat. derecho', type: 'rearRight', image: null, object2detect: 'wheel',
      bgImage: '../../assets/images/logo/camera.svg', isBGShow: true, newImg: 'newRight', uploadOK: true, enableAI: false
    },
    {
      id: 'front-right', name: 'Right', name_es: 'Lat. derecho', type: 'frontRight', image: null, object2detect: 'wheel',
      bgImage: '../../assets/images/logo/camera.svg', isBGShow: true, newImg: 'newFrontRight', uploadOK: true, enableAI: false
    }
  ];

  autoDetection: any;
  authPhoto: Boolean = false;
  canvasWidth: number;
  canvasHeight: number;
  canvasForHDImage: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  currentImageType: string;
  currentImageIndex: number;
  enableCamera: Boolean = false;
  infoModalBright: Boolean = false;
  intervalTime: number = 1000;
  isIos: Boolean = false;
  isModalOpen = 0;
  msg: string = '';

  luminosity = {
    bright: false,
    dark: false
  }

  private check: {
    black: boolean,
    ios: boolean,
    screener: boolean,
    cropped: boolean,
    badLight: boolean,
    dirty: boolean,
    transparent: boolean,
    occlusion: boolean,
  }

  // screener;

  photoType: string;

  notCar = [];
  notType = [];
  object: string = '';
  openNotification: Boolean = false;
  photo2Detect: HTMLCanvasElement;
  photo2DetectCtx: CanvasRenderingContext2D;
  preview: any;
  stream2Delete: any;
  actualSelfadjust;
  sidePictures
  supportWebGL2: Boolean;
  vertical: Boolean = true;
  video: HTMLVideoElement;
  loaded: Boolean = false;
  firstTime: Boolean = true;
  oneTime: number = 0;
  companyEnableIA: Boolean;
  viewPhoto: Boolean = false;
  errors = { isNotPart: false, isScreener: false, isBadLight: false, isDirty: false, isCropped: false };
  // isNotPart: Boolean = false; // controla si la imagen tomada es incorrecta, no pertenecea a la parte que se pide
  showSkip: Boolean = false; // fuerza a mostrar el mensaje para que puedas aceptar la foto aunque sea incorrecta
  imageValidity: ImageValidation;
  errorMessage: string;
  loadingCameraAndAI: Boolean = false;
  tryIAFlag: Boolean = true;
  carPartDetection: Boolean = true;
  carPartBlocker: Boolean = true;
  occlusionBlocker: Boolean = false;
  plateType: string = 'normal';
  carparts = {
    'front': 'front',
    'rear': 'rear',
    'frontLeft': 'front left',
    'frontRight': 'front right',
    'rearLeft': 'rear left',
    'rearRight': 'rear right'
  }
  reopened: boolean = false;
  unfixed: boolean = false;
  unfixedArray: boolean[];
  previuosUnfixedArray: boolean[];
  attempts: number = 0;
  backCameras: any;
  deviceRotationSrc: string = this.remoteService.selfAdjustService.rotateDevice || "../../assets/images/gifgirar.gif";
  blockFitting: boolean = true;
  pushDataSub: Subscription;
  loading: boolean;
  destroyed: boolean = false;
  silhouettes: ISilhouette[] = [];
  disabledButton360Help = true;
  acceptedHelp = false;
  fixedTaken = false;
  shouldHideRotateButton = false;
  showLegalFooter: Boolean = null;

  checkingImage = false;

  private default_max_retries = 2;
  private warnings: IWarnings;
  private alertWarnings: { [key: string]: Warning[] } = {};
  private block = false;
  private forceImgValidityParts: { [key: string]: IWarnings | number } = {};

  private orientation = {
    get: () => {
      let result: 'portrait' | 'landscape';

      try {
        result = window.screen.orientation.type.includes('portrait') ? 'portrait' : 'landscape';
      } catch (e) {
        // Older versions of iOS trigger event at start and is still portrait when changing to landscape
        result = !window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape';
      }

      return result;
    },
    set: () => {
      setTimeout(() => {
        this.vertical = this.orientation.get() === 'portrait';
      }, 150);
      setTimeout(() => {
        this.video = document.getElementById('videoElement') as HTMLVideoElement;
        this.video.srcObject = this.stream2Delete
      }, 200);
    }
  }

  private static readonly RETRYABLE_ALERTS = ['screener', 'bad_light', 'dirty', 'cropped'];
  private static readonly ALL_ALERTS = [
    ...SidePicturesComponent.RETRYABLE_ALERTS,
    'black_image',
    'IOS_error',
    'transparent',
    'wrong_zone',
    'dark',
    'bright'
  ];

  constructor(
    public remoteService: RemoteLibraryService,
    public router: Router,
    public cd: ChangeDetectorRef,
  ) {
    // Initialize warnings for each image type
    this.pictureDatas.forEach(data => {
      this.alertWarnings[data.type] = SidePicturesComponent.ALL_ALERTS.map(name => ({
        name,
        result: false,
        ...(SidePicturesComponent.RETRYABLE_ALERTS.includes(name) ? { count: 0 } : {})
      }));
    });
  }


  ngOnInit() {
    this.destroyed = false;
    this.isIos = navigator.userAgent.indexOf('iPhone') >= 0;
    this.supportWebGL2 = this.detectWebGLContext() || this.isIos;
    this.sidePictures = this.remoteService.selfAdjustService.myPage('side-pictures');
    this.companyEnableIA = (localStorage.getItem('enableIA') != undefined || this.remoteService.selfAdjustService.actualCompany.enableIA) ? true : false;
    if (!this.supportWebGL2 || !this.companyEnableIA) {
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.actualPage.value, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
    } else {
      this.actualSelfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    }
    this.silhouettes = this.remoteService.selfAdjustService.getSilhouettes();
    this.showLegalFooter = this.remoteService.selfAdjustService.actualCompany.showLegalFooter;
    const { currentPage } = this.remoteService.selfAdjustService;
    const page = this.remoteService.selfAdjustService.myPage(currentPage);
    const defaultWarnings = {
      is_screen: {
        block: false,
        max_retries: 2
      }
    }
    this.warnings = page.warnings || defaultWarnings;
  }

  ngAfterContentInit() {
    this.loadModels().then(async () => {
      const stream = await this.remoteService.nativeCameraService.autoInitVideo(this.destroyed, this.backCameras, this.attempts);
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        this.backCameras = this.remoteService.selfAdjustService.filterBackCameras(devices);
      });
      this.stream2Delete = stream;
      window['stream'] = stream;
      this.video.srcObject = stream;

      this.remoteService.nativeCameraService.on.stream.subscribe((stream) => {
        this.stream2Delete = stream;
        window['stream'] = stream;
        this.video.srcObject = stream;
      });
    });
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    if (this.sidePictures.pictureDatas) {
      this.pictureDatas = this.sidePictures.pictureDatas;
    }
    this.carPartDetection = this.sidePictures.carPartDetection === false ? this.sidePictures.carPartDetection : true;
    this.carPartBlocker = this.sidePictures.carPartBlocker === false ? this.sidePictures.carPartBlocker : true;
    this.occlusionBlocker = this.sidePictures.occlusionBlocker === true ? this.sidePictures.occlusionBlocker : false;
    this.plateType = this.sidePictures.plateType ? this.sidePictures.plateType : 'normal';
    this.canvasWidth = screen.width < screen.height ? (screen.width) * 16 / 9 : (screen.height) * 16 / 9;
    this.canvasHeight = screen.width < screen.height ? screen.width : (screen.height);

    this.orientation.set();

    try {
      window.screen.orientation.addEventListener('change', this.orientation.set, false);
    } catch (e) {
      window.addEventListener('orientationchange', this.orientation.set, false);
    }

    this.canvasForHDImage = document.createElement('canvas');
    this.photo2Detect = document.createElement("canvas");
    this.photo2DetectCtx = this.photo2Detect.getContext('2d');
    this.photo2Detect.width = this.canvasWidth;
    this.photo2Detect.height = this.canvasHeight;
    this.video = document.getElementById('videoElement') as HTMLVideoElement;
    this.loaded = true;
  }

  ngAfterContentChecked() {
    if (this.actualSelfadjust && this.oneTime < 1) {
      if (this.actualSelfadjust.reopenInfo && this.sidePictures.sidePictures) {
        this.reopened = true;
        this.unfixedArray = [];
        this.unfixed = this.remoteService.selfAdjustService.isPageFixed('side-pictures') ? false : true;
        for (let data of this.sidePictures.sidePictures) {
          for (let img of this.pictureDatas) {
            if (data.type == img.type) {
              img.fix = data.fix;
              this.unfixedArray.push(!data.fix);
            }
          }
        }
        this.previuosUnfixedArray = [...this.unfixedArray];
      }
      this.updatePage();
      this.oneTime++;
    }
  }

  loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }

  switchCamera() {
    this.remoteService.nativeCameraService.switchCamera(this.video, this.stream2Delete, this.backCameras);
  }

  async predictObjectFitting(result) {
    const prediction = await ia.predictObjectFitting(result);
    return prediction;
  }

  clickForced(carimage, event) {
    if (carimage.fix == undefined || carimage.fix == true) {
      if (event.isTrusted === false || event.target.className === 'upImg') {
        document.getElementById(`${carimage.newImg}`).click();
      }
    }
  }

  closeCamera() {
    this.disabledButton360Help = true;
    if (this.acceptedHelp) {
      this.acceptedHelp = false;
    }
    clearInterval(this.autoDetection);
    this.cameraControl(false)
  }

  detectPhoto() {
    setTimeout(() => {
      this.disabledButton360Help = false;
    }, 3000);
    this.infoModalBright = true;
    this.autoDetection = setInterval(() => {
      if (this.firstTime && this.pictureDatas[this.currentImageIndex].enableAI) {
        var t0 = performance.now()
      }
      this.photo2DetectCtx.drawImage(this.video, 0, 0, this.canvasWidth, this.canvasHeight);
      var data = this.photo2DetectCtx.getImageData(0, 0, this.photo2Detect.width, this.photo2Detect.height).data;
      var colorSum = 0;
      for (var x = 0, len = data.length; x < len; x += 4) {
        colorSum += Math.floor(0.299 * data[x] + 0.587 * data[x + 1] + 0.114 * data[x + 2]);
      }
      var brightness = Math.floor(colorSum / (this.photo2Detect.width * this.photo2Detect.height));

      this.luminosity.bright = brightness > 200;
      this.luminosity.dark = brightness < 80;

      if (this.pictureDatas[this.currentImageIndex].enableAI) {
        const cropImg = ia.cropObjectFitting(this.photo2DetectCtx, this.photo2Detect.width, this.photo2Detect.height, this.currentImageType);
        this.predictObjectFitting(cropImg).then(prediction => {
          if (prediction[0].label == this.pictureDatas[this.currentImageIndex].object2detect) {
            this.authPhoto = true;
          } else {
            this.authPhoto = false;
          }
          if (this.firstTime) {
            var t1 = performance.now();
            this.firstTime = false;
            this.intervalTime = (t1 - t0) > 500 ? (t1 - t0) : 500;
          }
        })
      } else {
        this.authPhoto = true;
        this.blockFitting = false;
      }

    }, this.intervalTime)
  }

  detectWebGLContext() {
    var canvas = document.createElement("canvas");
    try {
      var gl = canvas.getContext("webgl2");
      if (gl) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error creating webgl2 context", error);
      return false;
    }

  }

  getTitleName(name) {
    return this.remoteService.selfAdjustLanguageService.strLang(name);
  }

  public getInformation(imageType: string) {
    const currentWarnings = this.alertWarnings[imageType];

    return {
      warnings: currentWarnings
    }
  }

  async imageUpload(image: string, imageType: string, index: number, coordinate?) {
    let oldImage;

    if (this.pictureDatas[index]) {
      oldImage = this.pictureDatas[index].image;
    }
    this.isModalOpen = 0;
    this.pictureDatas[index].isBGShow = false;
    this.pictureDatas[index].loading = true;
    this.pictureDatas[index].image = image;
    this.cd.detectChanges();

    const newImg = {
      name: image,
      type: imageType
    };
    const contentType = image.slice(image.indexOf('image/'), image.indexOf(';'));
    const extension = contentType.split('image/')[1];
    const caption = this.pictureDatas[index].name;
    const { id, security_key } = this.actualSelfadjust;

    try {
      let media_url: string, media_id: string;
      try {
        // Get media url
        ({ media_url, media_id } = await this.remoteService.selfAdjustService.getMediaUrl(id, extension).toPromise());
      } catch (e) {
        console.log('Error in getMediaUrl', e);
        throw e;
      }

      try {
        // Upload image
        await this.remoteService.selfAdjustService.bucketImage(image, media_url, contentType).toPromise();
      } catch (e) {
        console.log('Error in bucketImage', e);
        throw e;
      }

      const logInfo = {
        component: 'side-pictures',
        action: 'push-image'
      };

      try {
        // Update selfadjust
        await this.remoteService.selfAdjustService.addImage(id, security_key, media_id, imageType, extension, logInfo, coordinate, caption, undefined, undefined, this.getInformation(imageType)).toPromise();
      } catch (e) {
        console.log('Error in addImage', e);
        throw e;
      }
      // Update locally
      Object.keys(this.errors).forEach((prop) => this.errors[prop] = false);
      this.pictureDatas[index].loading = false;
      this.remoteService.selfAdjustService.updateLocalImages(newImg);

      if (this.sidePictures.fixed !== undefined && !this.sidePictures.fixed) {
        this.isFixed(index);
      }

      if (this.pictureDatas[index].fix) {
        delete this.pictureDatas[index].fix;
      }
      // Set upload as OK
      this.pictureDatas[index].uploadOK = true;
      this.cd.detectChanges();
    } catch (e) {
      this.pictureDatas[index].loading = false;
      this.showNotification(index);
      if (oldImage) {
        this.pictureDatas[index].image = oldImage;
        this.pictureDatas[index].loading = false;
      }
    }
  }

  isFixed(index) {
    this.unfixedArray[index] = true;
    if (!this.unfixedArray.includes(false)) {
      this.unfixed = false;
      this.remoteService.selfAdjustService.fixedPage('side-pictures');
    }
  }

  async loadModels() {
    let loaded: boolean;

    try {
      loaded = await this.remoteService.aiService.ready();
    } catch (e) {
      loaded = false;
    }
    if (!loaded) {
      this.remoteService.aiService.load({ carAI: ia, motoAI: undefined });
      await this.remoteService.aiService.ready();
    }
  }

  loadTemplates(imageType, index) {
    this.cameraControl(true);
    this.authPhoto = false;
    this.photoType = this.pictureDatas[index].type;
    this.blockFitting = (this.pictureDatas[index].blockFitting != undefined) ? this.pictureDatas[index].blockFitting : true;
    this.currentImageType = imageType;
    this.currentImageIndex = index;
    this.object = this.pictureDatas[index].object2detect;
    const idName = (this.currentImageType === 'front' || this.currentImageType === 'rear')
      ? this.currentImageType + this.plateType : this.currentImageType;
    if (document.getElementById(`${idName}Photo`)) {
      document.getElementById(`${idName}Photo`).style.display = 'flex';
    }
    this.cd.detectChanges();
  }

  somoPhoNotTaken() {
    if (!this.sidePictures.mandatory) {
      return false;
    }
    return this.pictureDatas.some((picture) => {
      return !picture.image;
    });
  }

  somePhotoLoading() {
    return this.pictureDatas.some((picture) => {
      return picture.loading;
    });
  }

  somePhotoUploadingError() {
    return this.pictureDatas.some((picture) => {
      return !picture.uploadOK;
    });
  }

  public cant = () => this.somePhotoUploadingError() || this.somoPhoNotTaken() || this.somePhotoLoading() || this.unfixed || this.loading;

  movePage(action) {

    if (action == 'next') {
      if (this.cant()) {
        return;
      }

      this.loading = true;

      let data = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        status: this.remoteService.selfAdjustService.AppStatus.inprocess,
        logInfo: {
          component: 'side-pictures',
          action: 'continue'
        },
        fields2Update: {}
      };
      if (this.remoteService.selfAdjustService.getReopenInfo() && this.sidePictures.fixed != undefined) {
        data.fields2Update['reopenInfo'] = { pages: null };
        data.fields2Update['reopenInfo'].pages = this.remoteService.selfAdjustService.getReopenInfo()
      }
      this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe((res) => {
        this.remoteService.selfAdjustService.toggleFullscreen(false);
        console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
        this.router.navigate([...this.remoteService.selfAdjustService.nextPage, { secretKey: this.remoteService.selfAdjustService.secretKey }]);
      },
        err => {
          console.log("Error in selfadjust-update", err);
          let index = undefined;
          this.showNotification(index);
        });
    } else {
      this.loading = true;
      this.remoteService.selfAdjustService.toggleFullscreen(false);
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([...this.remoteService.selfAdjustService.backPage, { secretKey: this.remoteService.selfAdjustService.secretKey }]);
    }
  }

  showNotification(index) {
    this.openNotification = true;
    setTimeout(() => {
      this.openNotification = false;
      if (typeof index === 'number') this.pictureDatas[index].uploadOK = false;
      this.loading = false;
      this.cd.detectChanges();
    }, 5000);
    this.cd.detectChanges();
  }

  public async takePhoto() {
    console.log('Checking image: ', this.checkingImage);
    if(this.checkingImage) {
      return;
    }

    this.checkingImage = true;

    console.log('Inicio medir tiempo takePhoto', Date.now());
    clearInterval(this.autoDetection);
    this.canvasForHDImage.width = this.video.videoWidth;
    this.canvasForHDImage.height = this.video.videoHeight;
    this.canvasForHDImage.getContext('2d').drawImage(this.video, 0, 0);
    this.photo2DetectCtx.drawImage(this.video, 0, 0, this.canvasWidth, this.canvasHeight);
    const url = this.photo2Detect.toDataURL('image/jpeg', this.remoteService.nativeCameraService.quality.compression);

    this.preview = url;

    const check = await this.remoteService.checkImageService.check(this.photo2Detect, this.currentImageType as ImageType, this.warnings);
    await this.setError(check);
    
    this.viewPhoto = true;

    this.checkingImage = false;
    console.log('Fin medir tiempo takePhoto', Date.now());
    this.cd.detectChanges();
  }

  private async setError(check: ICheckImageResult) {
    const error = this.remoteService.languageService.strLang(check.error);
    this.check = {
      black: check.black,
      ios: check.ios,
      screener: false,
      cropped: false,
      badLight: false,
      dirty: false,
      transparent: check.transparent,
      occlusion: false
    }

    // By default image have not errors
    Object.keys(this.errors).forEach((prop) => this.errors[prop] = false);

    this.updateAlert('black_image', check.black);
    this.updateAlert('IOS_error', check.ios);
    this.updateAlert('transparent', check.transparent);
    
    let isScreener: boolean, isBadLight: boolean, isDirty: boolean, isCropped: boolean, isNotPart: boolean;
    if (check.valid) {
      try {
        [isScreener, isBadLight, isDirty, isCropped, isNotPart] = await Promise.all([
          check.screener,
          check.badLigth,
          check.dirty,
          check.cropped,
          this.carPartDetection && check.occlusion.then(([{ label }] = [{ label: undefined }]) => {
            return label && label !== this.currentImageType && this.carPartBlocker as boolean;
          })
        ]);

        this.check.screener = this.errors.isScreener = isScreener;
        this.check.badLight = this.errors.isBadLight = isBadLight;
        this.check.dirty = this.errors.isDirty = isDirty;
        this.check.cropped = this.errors.isCropped = isCropped;
        this.check.occlusion = this.errors.isNotPart = isNotPart;

        this.updateAlert('screener', isScreener);
        this.updateAlert('bad_light', isBadLight);
        this.updateAlert('dirty', isDirty);
        this.updateAlert('cropped', isCropped);
        this.updateAlert('wrong_zone', isNotPart);
      } catch (e) {
        console.error(e);
      }

    }

    this.updateAlert('dark', this.luminosity.dark);
    this.updateAlert('bright', this.luminosity.bright);

    const errors = [!check.valid, isScreener, isBadLight, isDirty, isCropped, isNotPart].filter(Boolean).length;
    const onlyIsNotPart = errors === 1 && isNotPart;
    if (errors > 0) {
      // Substract one retry for every each error
      // Check configuration of company
      if (this.warnings) {
        // Set configuration
        if (this.forceImgValidityParts[this.currentImageType] === undefined) {
          this.forceImgValidityParts[this.currentImageType] = JSON.parse(JSON.stringify(this.warnings)) as IWarnings;
        }
        const warnings = this.forceImgValidityParts[this.currentImageType] as IWarnings;
        // Substract one for every each error
        if (isScreener && warnings.is_screen && warnings.is_screen.max_retries) {
          warnings.is_screen.max_retries--;
        }

        if (isBadLight && warnings.is_bad_light && warnings.is_bad_light.max_retries) {
          warnings.is_bad_light.max_retries--;
        }

        if (isDirty && warnings.is_dirty && warnings.is_dirty.max_retries) {
          warnings.is_dirty.max_retries--;
        }

        if (isCropped && warnings.is_cropped && warnings.is_cropped.max_retries) {
          warnings.is_cropped.max_retries--;
        }
      } else {
        const retries = (this.forceImgValidityParts[this.currentImageType] as number) !== undefined ? (this.forceImgValidityParts[this.currentImageType] as number) : this.default_max_retries;

        if (retries) {
          this.forceImgValidityParts[this.currentImageType] = retries - 1;
        }
      }

      if (this.warnings) {
        const warnings = this.forceImgValidityParts[this.currentImageType] as IWarnings;
        // La pantalla de bloqueo aparece cuando se han alcanzado el máximo de intentos de alguna de las alertas configuradas
        const block = Object.values(warnings || {}).some(({ max_retries, block }) => !max_retries && block);
        
        if (block) {
          const part = this.carparts[this.currentImageType]
          return this.remoteService.selfAdjustService.block({ isScreener, isBadLight, isDirty, isCropped, isNotPart, part });
        }
        // Get actual warnings
        const actual = [].concat(isScreener ? warnings.is_screen : [])
                         .concat(isBadLight ? warnings.is_bad_light : [])
                         .concat(isDirty ? warnings.is_dirty : [])
                         .concat(isCropped ? warnings.is_cropped : []);
        // Check at least one, every can be skipped and at least one of them can be skipped
        const canSkip = actual.length && actual.every(({ block, max_retries }) => !block && !max_retries);
        this.showSkip = canSkip || onlyIsNotPart;
      } else {
        this.showSkip = this.forceImgValidityParts[this.currentImageType] === 0 && this.preview.includes('image');
      }
    }
    // Company hasn't configured custom alerts
    if (this.warnings === undefined) {
      const errorMessages = [];

      if (isNotPart) {
        errorMessages.push(this.remoteService.selfAdjustLanguageService.strLang(`The ${this.carparts[this.currentImageType]} part of the vehicle is not correctly shown.`));
      }

      if (isScreener) {
        errorMessages.push(this.remoteService.selfAdjustLanguageService.strLang("The photographs should not be on the screen of a device."));
      }

      this.errorMessage = `
      <ul>
        ${errorMessages.reduce((output, errorMessage) => {
          return `${output}
                <li>
                  · ${errorMessage}
                </li>
              `;
        }, '')}
            <li>
              ${this.remoteService.selfAdjustLanguageService.strLang("Please, take it again.")}
            </li>
          </ul>
        `;
      return;
    }
    // Company configured custom alerts
    if (error) {
      this.errorMessage = error;
      return;
    }

    const translate = this.remoteService.selfAdjustLanguageService.strLang.bind(this.remoteService.selfAdjustLanguageService);
    const retries = (() => {

      let retries = this.forceImgValidityParts[this.currentImageType];

      if (this.warnings) {
        ([ retries ] = Object.values(this.forceImgValidityParts[this.currentImageType] || {}).map(({ max_retries }) => max_retries).sort());
      }
      
      return retries !== 0 ? (retries as number) - 1 : retries;
    })();

    const block = (() => {
      let block = this.block;

      if (this.warnings) {
        const warnings = this.forceImgValidityParts[this.currentImageType] as IWarnings;
        block = Object.values(warnings || {}).some(({ block }) => block);
      }

      return block;
    })();
    // Always calculate this message to save it on localStorage an render on blocked page
    if (errors > 1) {
      const messages: string[] = [];

      if (isScreener) {
        messages.push(translate("Photos should not be on the screen of a device"));
      }

      if (isBadLight) {
        messages.push(translate("The photo is a little dark"));
      }

      if (isDirty) {
        messages.push(translate("The vehicle appears to be covered in dust or dirt"));
      }

      if (isCropped) {
        messages.push(translate("It looks like the vehicle part is cut off in the photo"));
      }

      if (isNotPart) {
        messages.push(translate(`The ${this.carparts[this.currentImageType]} part of the vehicle is not correctly shown.`));
      }

      const title = translate(retries || !block ? 'The photo has several problems' : 'If you continue taking the photo with these problems you will not be able to continue');

      this.errorMessage = `
        <span>
          ${title}:
        </span>
        <ul>
          ${messages.map((message) => `<li>· ${message}</li>`).join('')}
          <li>
            ${translate('Please, repeat the image')}.
          </li>
        </ul>
      `
    }
    if (errors === 1) {
      let message: string;
      let subtitle = translate('Please, take it again.');

      if (isScreener) {
        message = translate(retries || !block ? "Avoid taking the photo directly on the screen of a device" : "If you continue taking the photo on the screen you will not be able to continue");
      }

      if (isBadLight) {
        message = translate(retries || !block ? "The photo has lighting problems" : "If you continue taking the photo without luminosity you will not be able to continue");

        if (retries || !block) {
          subtitle = translate("Please take the photo in a better lit place");
        }
      }

      if (isDirty) {
        message = translate(retries || !block ? "The vehicle appears to be covered in dust or dirt" : "If you continue taking the photo with the vehicle covered in dirt, you will no longer be able to continue");

        if (retries || !block) {
          subtitle = translate("Please clean the surface of the car and repeat the photo again")
        }
      }

      if (isCropped) {
        message = translate(retries || !block ? "It looks like the vehicle part is cut off in the photo" : "If you continue taking the cropped photo of the vehicle you will not be able to continue");

        if(retries || !block) {
          subtitle = translate("Please make sure the vehicle is fully visible in the image");
        }
      }

      if (isNotPart) {
        const part = this.carparts[this.currentImageType];
        message = translate(`The ${part} part of the vehicle is not correctly shown.`);
      }

      this.errorMessage = `
        <ul>
          <li>· ${message}</li>
          <li>${subtitle}</li>
        </ul>
      `
    }
  }

  async acceptPhoto() {
    this.viewPhoto = false;
    this.errorMessage = undefined;
    this.closeCamera();
    const image = new Image();
    const coordinates = await this.remoteService.selfAdjustService.getCoordinates();
    image.src = this.canvasForHDImage.toDataURL('image/jpeg', this.remoteService.nativeCameraService.quality.compression);
    image.onload = () => {
      this.imageUpload(image.src, this.currentImageType, this.currentImageIndex, coordinates);
    };
  }

  discardPhoto() {
    Object.keys(this.errors).forEach((prop) => this.errors[prop] = false);
    this.viewPhoto = false;
    this.errorMessage = undefined;
    this.detectPhoto()
  }

  cameraControl(toggle) {
    if (toggle) {
      this.enableCamera = true;
    } else {
      const idName = (this.currentImageType === 'front' || this.currentImageType === 'rear')
        ? this.currentImageType + this.plateType : this.currentImageType;
      if (document.getElementById(`${idName}Photo`)) {
        document.getElementById(`${idName}Photo`).style.display = 'none';
      }
      this.enableCamera = false;
    }
  }

  changeCss(property, id) {
    for (let element of this.pictureDatas) {
      if (element.id == id && element.image != null) {
        switch (property) {
          case 'fill':
            return this.remoteService.selfAdjustService.fillColorOn;
          case 'stroke':
            return this.remoteService.selfAdjustService.strokeColorOn;
          case 'opacity':
            return ".4";
          default:
            return 'Err: No property found';
        }
      }
    }
    switch (property) {
      case 'fill':
        return this.remoteService.selfAdjustService.fillColorOFF;
      case 'stroke':
        return this.remoteService.selfAdjustService.strokeColorOFF;
      case 'opacity':
        return ".121";
      default:
        return 'Err: No property found';
    }
  }

  updatePage() {
    this.actualSelfadjust.images.map(e => {
      this.pictureDatas.forEach(element => {
        if (element.type == e.type) {
          element.image = e.name;
          element.isBGShow = false;
        }
        if (element.fix && !this.remoteService.selfAdjustService.isPageFixed('side-pictures')) {
          delete element.image;
          element.isBGShow = true
        }
      })
    });
  }

  /**
   * @description Funcion encargada de eliminar referencias externas que causen que el componente no termine su ciclo de vida
   */
  ngOnDestroy() {
    this.destroyed = true;
    if (this.stream2Delete) {
      this.stream2Delete.getTracks().forEach(track => track.stop());
      window['stream'] = undefined;
      this.video.srcObject = undefined;
    }

    try {
      window.screen.orientation.removeEventListener('change', this.orientation.set);
    } catch (e) {
      window.removeEventListener('orientationchange', this.orientation.set);
    }

    delete this.cd;
    console.log('Destroying Side-Pictures Component!');
  }

  public getSilhouette(src: string): string {
    let result;
    try {
      const [rawType] = src.split('/').reverse();
      const [type, ok = ''] = rawType.split('.')[0].split('-');
      const hasOk = ok.includes('OK');
      const { images = { ok: src, base: src } } =
        this.silhouettes.find((item) => item.zone === type) || {};
      const silhouette = hasOk ? images.ok : images.base;

      result = silhouette || src;
    } catch (e) {
      result = src;
    }

    return result;
  }

  public somePhotoTaken(): boolean {
    return this.pictureDatas.some((picture) => picture.image);
  }

  public someFixedPhotoTaken(): boolean {
    let taken = false;
    for (let i = 0; i < this.unfixedArray.length && !taken; i++) {
      if (this.unfixedArray[i] && !this.previuosUnfixedArray[i]) {
        taken = true;
      }
    }
    return taken;
  }

  public close360Help(): void {
    this.acceptedHelp = true;
  }

  public helpIsShown(): boolean {
    return (
      (!this.somePhotoTaken() && !this.acceptedHelp) ||
      (this.unfixed && !this.someFixedPhotoTaken() && !this.acceptedHelp)
    );
  }

  /**
   * Updates a specific alert for the current image type
   * @param alertName - Name of the alert to update
   * @param result - New result value for the alert
   */
  private updateAlert(name: string, result: boolean) {
    const currentWarnings = this.alertWarnings[this.currentImageType];
    if (!currentWarnings) return;

    const warning = currentWarnings.find(w => w.name === name);
    if (!warning) return;

    warning.result = result;
    
    // If alert is retryable and result is true, increment count
    if (SidePicturesComponent.RETRYABLE_ALERTS.includes(name) && result) {
      warning.count = (warning.count || 0) + 1;
    }
  }
}
